:root {
  --bg-color: #404041;
  --primary-color: #F48120;
  --secondary-color: #FAAD3F;
  --card-color: #4D4D4E;
}

.app {
  min-height: 100vh;
  width: 100vw;
  height: auto;
  background-color: var(--bg-color);
}

.header {
  position: fixed;
  left: 50px;
  top: 50px;
  font-size: 64px;
  color: var(--primary-color);
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  z-index: 10000 !important;
}

.btn-primary:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.btn-primary:focus {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}