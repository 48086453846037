.feed {
    max-width: 75%;
    padding: 150px;  
    margin: auto;
}

.refreshButton {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
}

.spin {
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.card {
    background-color: var(--card-color);
    margin-bottom: 10px;
}

.card-title {
    color: white;
}

.card-subtitle {
    color: var(--secondary-color);
}

.card-text {
    color: white;
}

.card img {
    max-width: 100%;
}

.sortButton {
    position: fixed;
    top: 7%;
    right: 12%;
}

.dropdown-menu {
    background-color: var(--bg-color);
}

.dropdown-item {
    background-color: var(--bg-color);
    color: white !important;
    transition: ease-in-out 100ms color;
}

.dropdown-item:hover {
    background-color: var(--card-color);
}

.dropdown-item:focus {
    background-color: var(--primary-color);
}

.footerButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.footerButtons button {
    background: transparent;
    border: none;
}

.footerButtons span {
    padding-left: 2px;
    padding-right: 2px;
    color: white;
}

.whiteLabel {
    color: white;
}

.darkInput {
    background-color: var(--bg-color) !important;
    border-color: slategray !important;
    color: white !important;
}

.submitButton {
    width: unset;
    float: right;
    margin-top: 10px;
}

.comment {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    border-top: 1px solid rgba(0,0,0,.125);
}

.commentTitle {
    color: var(--secondary-color);
    font-size: 14px;
}

.commentBody {
    margin: 0;
    color: white;
    font-size: 12px;
}

form {
    border-top: 1px solid rgba(0,0,0,.125);
}