.createButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createModal {
    width: 80vw !important;
    max-width: 80vw;
}

.editor {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    min-height: 20vh;
}

.notif {
    position: fixed;
    z-index: 100;
    top: 7%;
    left: 50%;
    transform: translateX(-50%);
}